import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import * as Api from 'service';
import useStore from 'context';
import { getError, getSuccessMessage } from 'appHelpers';
import {
  SURVEY_ACTIVATION_STATES,
  SURVEY_LIST_PAGE_OBJ,
  UI_TEXTS_OBJ
} from './constants';

import editImg from 'assets/images/edit-photo/edit_light.svg';
import removeImg from 'assets/images/icons/remove_red.png';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useDialog } from 'hooks';
import { formatDateDayMonthYear } from 'helpers/dateFormatHelpers';

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    '&:hover': {
      transform: 'scale(1.5)',
      transition: '.4s'
    }
  }
}));

export default function SurveysContent() {
  const classes = useStyles();
  const { setErrorMsg, setSuccessMsg } = useStore();
  const [surveys, setSurveys] = useState([]);
  const removeDialog = useDialog();

  const getSurveys = async () => {
    try {
      const { data } = await Api.surveys.getSurveyList();
      setSurveys(data);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const handleOpenRemoveDialog = (e, surveyId) => {
    e.stopPropagation();
    e.preventDefault();
    removeDialog.openDialog({ surveyId });
  };

  const handleRemoveSurvey = async () => {
    if (removeDialog.dialogData && removeDialog.dialogData.surveyId) {
      try {
        const res = await Api.surveys.deleteSurvey(
          removeDialog.dialogData.surveyId
        );
        setSuccessMsg(getSuccessMessage(res));
        getSurveys();
      } catch (err) {
        setErrorMsg(getError(err));
      }
    }
    removeDialog.closeDialog();
  };

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <div className="surveysContainer">
      <div className="surveysHead">
        <h6>{SURVEY_LIST_PAGE_OBJ.title}</h6>
        <Link to="/surveys/create">{SURVEY_LIST_PAGE_OBJ.createNew}</Link>
      </div>
      <div className="surveysBody">
        {surveys.map((survey, idx) => {
          const isActive = survey.active;
          const today = new Date();
          const expirationDate = new Date(survey.expirationDate);
          const isExpired = expirationDate < today;
          return (
            <Link key={idx} to={`/surveys/${survey._id}`}>
              <div className="surveysContant">
                <div className="contantText">
                  <p>{survey.title}</p>
                  <div className="contantImage">
                    <Link to={`/surveys/${survey._id}/edit`}>
                      <img
                        className={classes.actionIcon}
                        src={editImg}
                        alt="Edit"
                      />
                    </Link>
                    <img
                      onClick={(e) => handleOpenRemoveDialog(e, survey._id)}
                      className={classes.actionIcon}
                      width={14}
                      src={removeImg}
                      alt="Remove"
                    />
                  </div>
                </div>
                <div className="contentFooter">
                  <div className="activeSection">
                    <div
                      className={`activeLight ${
                        isActive
                          ? SURVEY_ACTIVATION_STATES.active
                          : SURVEY_ACTIVATION_STATES.inactive
                      }`}></div>
                    <span className="text_capitalize">
                      {isActive
                        ? SURVEY_ACTIVATION_STATES.active
                        : SURVEY_ACTIVATION_STATES.inactive}
                    </span>
                  </div>
                  {!!survey.expirationDate && (
                    <div className={`expiration ${isExpired && 'danger'}`}>
                      {isExpired ? UI_TEXTS_OBJ.expired : UI_TEXTS_OBJ.expires}:{' '}
                      {formatDateDayMonthYear(survey.expirationDate)}
                    </div>
                  )}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <ConfirmDialog
        open={removeDialog.isOpen}
        handleClose={removeDialog.closeDialog}
        handleConfirm={handleRemoveSurvey}
      />
    </div>
  );
}
