import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import * as Api from 'service';
import useStore from 'context';
import { getError, getSuccessMessage } from 'appHelpers';
import { STATION_TYPES_LIST_PAGE_OBJ, UI_TEXTS_OBJ } from './constants';

import editImg from 'assets/images/edit-photo/edit_light.svg';
import removeImg from 'assets/images/icons/remove_red.png';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useDialog } from 'hooks';
import { formatDateDayMonthYear } from 'helpers/dateFormatHelpers';

const useStyles = makeStyles(() => ({
  actionIcon: {
    '&:hover': {
      transform: 'scale(1.5)',
      transition: '.4s'
    }
  }
}));

export const StationTypes = () => {
  const classes = useStyles();
  const { setErrorMsg, setSuccessMsg } = useStore();
  const [spotTypes, setSpotTypes] = useState([]);
  const removeDialog = useDialog();

  const getStationTypes = async () => {
    try {
      const { data } = await Api.spotTypes.getAll();
      setSpotTypes(data);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const handleOpenRemoveDialog = (e, typeId) => {
    e.stopPropagation();
    e.preventDefault();
    removeDialog.openDialog({ typeId });
  };

  const handleRemoveSurvey = async () => {
    if (removeDialog.dialogData && removeDialog.dialogData.typeId) {
      try {
        const res = await Api.spotTypes.delete(removeDialog.dialogData.typeId);
        setSuccessMsg(getSuccessMessage(res));
        getStationTypes();
      } catch (err) {
        setErrorMsg(getError(err));
      }
    }
    removeDialog.closeDialog();
  };

  useEffect(() => {
    getStationTypes();
  }, []);

  return (
    <div className="surveysContainer">
      <div className="surveysHead">
        <h6>{STATION_TYPES_LIST_PAGE_OBJ.title}</h6>
        <Link to="/station-types/create">
          {STATION_TYPES_LIST_PAGE_OBJ.createNew}
        </Link>
      </div>
      <div className="surveysBody">
        {spotTypes.map((type, idx) => {
          return (
            <div key={idx} className="surveysContant">
              <div className="contantText">
                <p className="spot-type-name capitalize">{type.name}</p>
                <div className="contantImage">
                  {type.default ? (
                    <p>{UI_TEXTS_OBJ.default}</p>
                  ) : (
                    <>
                      <Link to={`/station-types/${type._id}/edit`}>
                        <img
                          className={classes.actionIcon}
                          src={editImg}
                          alt="Edit"
                        />
                      </Link>
                      <img
                        onClick={(e) => handleOpenRemoveDialog(e, type._id)}
                        className={classes.actionIcon}
                        width={14}
                        src={removeImg}
                        alt="Remove"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="contentFooter">
                <div className="date">
                  {UI_TEXTS_OBJ.createdAt}:{' '}
                  {formatDateDayMonthYear(type.createdAt)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ConfirmDialog
        open={removeDialog.isOpen}
        content={UI_TEXTS_OBJ.confirmDialogContent}
        handleClose={removeDialog.closeDialog}
        handleConfirm={handleRemoveSurvey}
      />
    </div>
  );
};
