export const validationRules = {
  appId: {
    test: (value) => !!value,
    message: 'App is required'
  },
  version: {
    test: (value) => !!value,
    message: 'Version is required'
  },
  slug: {
    test: (value) => !!value,
    message: 'Page is required'
  }
};

export const changelogItemValidationRules = {
  title: {
    test: (value) => !!value,
    message: 'Title is required'
  },
  description: {
    test: (value) => !!value,
    message: 'Description is required'
  },
  image: {
    test: (value) => !!value,
    message: 'Image is required'
  }
};
