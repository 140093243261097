import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useStore from '../context';

export const usePutData = (Api, refresh, resetErrors) => {
  const history = useHistory();
  const { setSuccessMsg, setErrorMsg } = useStore();

  return useCallback(
    async (data, id) => {
      try {
        await Api(data, id);
        if (typeof refresh === 'function') {
          refresh();
        }

        setSuccessMsg('updated');
        resetErrors();
        history.goBack();
      } catch (e) {
        console.log(e);
        setErrorMsg(e.message || 'there was an error');
      }
    },
    [Api, refresh, setErrorMsg, setSuccessMsg]
  );
};
