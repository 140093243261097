import React, { useState, useCallback } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';

import { connect } from 'react-redux';

import { setHeaderSearchHover } from '../../reducers/ThemeOptions';
import { useHistory, useLocation } from 'react-router-dom';

const HeaderSearch = (props) => {
  const [search, setSearch] = useState('');
  const history = useHistory();
  const { headerSearchHover, setHeaderSearchHover } = props;

  const query = new URLSearchParams(useLocation().search);

  const updateSearch = useCallback(
    (e) => {
      e.preventDefault();
      query.set('search', search);
      query.set('page', 1);
      history.push(`?${query.toString()}`);
    },
    [query.toString(), search]
  );

  const toggleHeaderSearchHover = () => {
    setHeaderSearchHover(!headerSearchHover);
  };

  return (
    <form onSubmit={updateSearch} className="header-search-wrapper">
      <div
        className={clsx('search-wrapper', {
          'is-active': headerSearchHover
        })}>
        <label className="icon-wrapper" htmlFor="header-search-input">
          <FontAwesomeIcon icon={['fas', 'search']} />
        </label>
        <TextField
          onFocus={toggleHeaderSearchHover}
          onBlur={toggleHeaderSearchHover}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          id="header-search-input"
          name="header-search-input"
          type="search"
          placeholder="Search terms..."
          variant="outlined"
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  headerSearchHover: state.ThemeOptions.headerSearchHover
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderSearchHover: (enable) => dispatch(setHeaderSearchHover(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
