import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  ButtonGroup,
  Card,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

import * as Api from 'service';
import {
  defaultUpdatePriority,
  DEVICES_OBJ,
  PRIORITIES_OBJ,
  PRIORITY_SELECT_LABEL,
  SELECTED_DEVICE_LABEL,
  SELECT_DEVICE_DESCRIPTION,
  VERSION_PLACEHOLDER
} from './constants';
import { BTN_TEXTS, BUTTON_VARIANTS, TEXTFIELD_VARIANTS } from 'constants.js';

import { Radio } from 'components/Radio';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function LivePreviewExample() {
  const [activeTab, setActiveTab] = useState(DEVICES_OBJ.android.name);
  const [priority, setPriority] = useState(defaultUpdatePriority);
  const [version, setVersion] = useState(null);
  const [actualVersion, setActualVersion] = useState(null);

  const selectedDevice =
    activeTab === DEVICES_OBJ.ios.value
      ? DEVICES_OBJ.ios.name
      : DEVICES_OBJ.android.name;
  const platform = selectedDevice?.toLowerCase();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleChange = (event, newValue) => {
    setPriority(newValue);
  };

  const handleChangeVersion = ({ value }) => {
    setVersion(value);
  };

  const handleResetUpdate = ({ version }) => {
    setActualVersion(version);
    setPriority(defaultUpdatePriority);
    setVersion('');
  };

  const handleSave = async () => {
    await Api.adminApplication.updateVersion({ platform, version, priority });
    await Api.adminApplication
      .getVersion({ platform })
      .then((res) => handleResetUpdate(res.data));
  };

  useEffect(() => {
    Api.adminApplication
      .getVersion({ platform })
      .then((res) => handleResetUpdate(res.data));
  }, [selectedDevice, platform]);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xl={12}>
          <Card className="card-box">
            <div className="card-header">
              <div className="card-header--title">
                <b>
                  {SELECTED_DEVICE_LABEL} {selectedDevice}
                </b>
                <small className="d-block text-capitalize mt-1">
                  {SELECT_DEVICE_DESCRIPTION}
                </small>
              </div>
              <div className="card-header--actions">
                <ButtonGroup size="small" variant="text">
                  <Button
                    className={clsx('btn-dark btn-transition-none', {
                      active: activeTab === DEVICES_OBJ.ios.value
                    })}
                    onClick={() => {
                      toggle(DEVICES_OBJ.ios.value);
                    }}>
                    {DEVICES_OBJ.ios.name}
                  </Button>
                  <Button
                    className={clsx('btn-dark btn-transition-none', {
                      active: activeTab === DEVICES_OBJ.android.value
                    })}
                    onClick={() => {
                      toggle(DEVICES_OBJ.android.value);
                    }}>
                    {DEVICES_OBJ.android.name}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="content">
              <div className={'tab-item-wrapper active'}>
                <div>
                  <TextField
                    name="version"
                    id="filled-basic"
                    label={VERSION_PLACEHOLDER}
                    variant={TEXTFIELD_VARIANTS.filled}
                    onChange={({ target }) => handleChangeVersion(target)}
                    value={version}
                    className={'MuiInputLabel-shrink'}
                  />
                  <p>Actual Version: {actualVersion}</p>
                </div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {PRIORITY_SELECT_LABEL}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="priority"
                    name="priority"
                    value={priority}
                    onChange={handleChange}>
                    <FormControlLabel
                      value={PRIORITIES_OBJ.low.value}
                      control={<Radio color={PRIORITIES_OBJ.low.color} />}
                      label={PRIORITIES_OBJ.low.name}
                    />
                    <FormControlLabel
                      value={PRIORITIES_OBJ.medium.value}
                      control={<Radio color={PRIORITIES_OBJ.medium.color} />}
                      label={PRIORITIES_OBJ.medium.name}
                    />
                    <FormControlLabel
                      value={PRIORITIES_OBJ.high.value}
                      control={<Radio color={PRIORITIES_OBJ.high.color} />}
                      label={PRIORITIES_OBJ.high.name}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <Button
                onClick={handleSave}
                variant={BUTTON_VARIANTS.contained}
                className="m-2 btn-primary btn-gradient shadow-none bg-night-sky save_btn">
                {BTN_TEXTS.save}
              </Button>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
