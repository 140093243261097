import React from 'react';
import { withStyles, Radio as MuiRadio } from '@material-ui/core';

const Radio = (props) => {
  return <MuiRadio color="default" {...props} />;
};

const StyleWithThemeProps = (props) => {
  return withStyles(
    () => ({
      root: {
        color: props?.color,
        '&$checked': {
          color: props?.color
        }
      }
    }),
    { withTheme: true }
  )(Radio);
};

const StyledRadio = (props) => {
  const WithStylesRadioComponent = StyleWithThemeProps(props);
  return <WithStylesRadioComponent {...props} />;
};

export default StyledRadio;
