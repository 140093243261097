import React from 'react';

const WrapperSeamless = (props) => {
  const { sectionHeading, children, className } = props;

  return (
    <div className={`example-card-seamless mb-spacing-6 ${className}`}>
      {sectionHeading && (
        <h5 className="display-4 mb-4 font-weight-bold">{sectionHeading}</h5>
      )}
      {children}
    </div>
  );
};

export default WrapperSeamless;
