import Base from './base';

class Resource extends Base {
  constructor(path) {
    super();
    this.getAll = (query) => this.getRequest(path, query);
    this.getById = (id) => this.getRequest(`${path}/${id}`);
    this.create = (data) => this.postRequest(path, data);
    this.createNew = (data) => this.postRequest(`${path}/create`, data);
    this.update = (data) => this.putRequest(path, data);
    this.updateById = (id, data) => this.putRequest(`${path}/${id}`, data);
    this.sort = (data) => this.putRequest(`${path}/sort`, data);
    this.delete = (id) => this.deleteRequest(`${path}/${id}`);
    this.block = (id) => this.postRequest(`${path}/block/${id}`);
    this.updateVersion = (data) =>
      this.postRequest(`${path}/set-version`, data);
    this.getVersion = (data) =>
      this.getRequest(`${path}/get-version/?platform=${data?.platform}`);
    this.getUsers = (id) => this.getRequest(`${path}/${id}`);
    this.createSurvey = (data) => this.postRequest(`${path}/create`, data);
    this.updateSurvey = (data) => this.putRequest(`${path}/update`, data);
    this.getSurveyList = () => this.getRequest(`${path}/list`);
    this.getSurveyById = ({ surveyId }) =>
      this.getRequest(`${path}/${surveyId}`);
    this.getQuestionsBySurveyId = ({ surveyId }) =>
      this.getRequest(`${path}/${surveyId}/questions`);
    this.deleteSurvey = (surveyId) =>
      this.deleteRequest(`${path}/delete`, { surveyId });
    this.addQuestion = (data) =>
      this.postRequest(`${path}/${data.surveyId}/questions/create`, data);
    this.removeQuestion = ({ questionId }) =>
      this.deleteRequest(`${path}/question`, { questionId });
    this.getAllChangelogs = (query) => this.getRequest(path, query);
    this.createChangelog = () => this.postRequest(`${path}`);
    this.updateChangelog = (data, id) => this.putRequest(`${path}/${id}`, data);
    this.updateChangelogItemsOrder = (data, id) =>
      this.putRequest(`${path}/update-order/${id}`, data);
    this.getAllChangelogItems = () => this.getRequest(`${path}/item`);
    this.getChangelogById = ({ id, lang }) =>
      this.getRequest(`${path}/${id}`, { lang });
    this.getChangelogItems = (changelogId) =>
      this.getRequest(`${path}/item/`, { changelogId });
    this.createChangelogItem = (data) =>
      this.postFormDataRequest(`${path}/item`, data);
    this.updateChangelogItem = (data, id) =>
      this.postFormDataRequest(`${path}/item/${id}`, data);
    this.deleteChangelogItem = (id) => this.deleteRequest(`${path}/item/${id}`);
  }
}

export default Resource;
