import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import BuoysIcon from './icons/buoy.svg';
import SuggestionsIcon from './icons/conversation.svg';
import SurveysIcon from './icons/surveys.svg';

import Menu from '../../menu.json';

const SIDEBAR_ICONS = {
  Buoys: BuoysIcon,
  'Station suggestions': SuggestionsIcon,
  Surveys: SurveysIcon
};

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const [menuOpen, setMenuOpen] = useState(null);

  return (
    <>
      <PerfectScrollbar>
        {/* {sidebarUserbox && <SidebarUserbox />} */}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation menu</span>
          </div>
          <ul>
            {Object.keys(Menu).map((el, index) => (
              <li key={el.id || index}>
                {Array.isArray(Menu[el]) ? (
                  <>
                    <a
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenuOpen((st) => (st === index ? null : index));
                      }}
                      className={clsx({ active: menuOpen === index })}>
                      <span className="sidebar-icon">
                        {SIDEBAR_ICONS[el] ? (
                          <img alt="icon" src={SIDEBAR_ICONS[el]} />
                        ) : (
                          <VerifiedUserTwoToneIcon />
                        )}
                      </span>
                      <span
                        style={{
                          textTransform: 'capitalize'
                        }}
                        className="sidebar-item-label">
                        {el.replace('_', ' ')}
                      </span>
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {Menu[el].map((e, i) => (
                          <li key={e.id || i}>
                            <NavLink
                              exact
                              onClick={() => setSidebarToggleMobile(false)}
                              to={e.link}>
                              {e.name.replace('-', ' ')}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </>
                ) : (
                  <NavLink
                    to={Menu[el]}
                    onClick={() => {
                      setSidebarToggleMobile(false);
                      setMenuOpen(null);
                    }}>
                    <span className="sidebar-icon">
                      {el === 'Buoys' ? (
                        <img src={BuoysIcon} alt="buoys" />
                      ) : el === 'Station suggestions' ? (
                        <img src={SuggestionsIcon} alt="suggestions" />
                      ) : el === 'Surveys' ? (
                        <img src={SurveysIcon} alt="surveys" />
                      ) : (
                        <VerifiedUserTwoToneIcon />
                      )}
                    </span>
                    <span className="sidebar-item-label">
                      {el.replace('-', ' ')}
                    </span>
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
