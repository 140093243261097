export const OFFER_CODES_CARD = {
  name: 'Offer codes'
};

export const ANDROID_OFFER_CODES_CARD = {
  name: 'Android'
};

export const IOS_OFFER_CODES_CARD = {
  name: 'iOS'
};

export const CARD_NAMINGS = {
  total: 'Total',
  used: 'Used',
  freeTrial: 'Free Trial',
  successRate: 'Success Rate'
};
export const defaultUpdatePriority = 0;

export const SELECTED_DEVICE_LABEL = 'Selected Device:';
