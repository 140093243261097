import { numberWithSingleDotRegex } from 'regexes';

export const validationRules = {
  code: {
    test: (value) => !!value,
    message: 'Code is required'
  },
  title: {
    test: (value) => !!value,
    message: 'Title is required'
  },
  type: {
    test: (value) => !!value,
    message: 'Type is required'
  },
  latitude: [
    {
      test: (value) => !!value,
      message: 'Latitude is required'
    },
    {
      test: (value) => numberWithSingleDotRegex.test(value),
      message: 'Latitude must be a valid number'
    }
  ],
  longitude: [
    {
      test: (value) => !!value,
      message: 'Longitude is required'
    },
    {
      test: (value) => numberWithSingleDotRegex.test(value),
      message: 'Longitude must be a valid number'
    }
  ]
};
