import React, { useState } from 'react';

import Table from 'components/tables/table';
import { WrapperSeamless } from 'layout-components';
import { useGetList } from 'hooks';
import * as Api from 'service';
import {
  DEVICES_SELECT_VALUE_OBJ,
  ITEM_STATUSES,
  QUERY_KEY,
  USER_FORM_NAMES,
  USER_HAS_NO_SUBSCRIPTION,
  USER_STATUSES
} from './constants';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { DEVICES_OBJ } from 'components/TabCard/constants';
import {
  ACTIVATION_STATUSES,
  BUOYS_CREATE_LINK,
  NONE,
  SUBSCRIBED_STATUSES,
  SUBSCRIPTION,
  TABLE_HEAD_TITLES
} from 'constants.js';
import { formatDateDayMonthYear } from 'helpers/dateFormatHelpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const defaultQuery = {
  sort: 'createdAt',
  order: 'desc',
  device_type: '',
  blocked: '',
  in_subscription: ''
};

const defaultFilterState = {
  device: '',
  blocked: '',
  subscription: ''
};

export function Users() {
  const classes = useStyles();

  const [query, setQuery] = useState(defaultQuery);
  const [processingItemId, setProcessingItemId] = useState(null);
  const [filterState, setFilterState] = useState(defaultFilterState);

  const [items, total, setItems] = useGetList(Api.users.getAll, query);

  const handleBlockUnblock = (id) => {
    setProcessingItemId(id);
    Api.users.block(id).finally(() => setProcessingItemId(null));
  };

  const rows = items?.map((item) => {
    const lastDevice = item.devices?.length
      ? item.devices[item.devices.length - 1]
      : {};
    return (
      <>
        <td className="text-left px-4">
          <div className="font-size-sm">{item.name}</div>
        </td>
        <td className="text-left px-4">
          <div className="font-size-sm">{item.email}</div>
        </td>
        <td className="text-left px-4">
          <div className="font-size-sm">
            {DEVICES_OBJ[lastDevice.platform]?.name || '-'}
          </div>
        </td>
        <td className="text-left px-4">
          <div className="font-size-sm">
            {lastDevice.current_app_version || '-'}
          </div>
        </td>
        {/* TEMPORART HIDDEN */}
        {/* <td className="text-left px-4">
          <div className="font-size-sm">
            {item.has_subscription
              ? item.subscription || item.subscription_plan
              : USER_HAS_NO_SUBSCRIPTION}
          </div>
        </td> */}
        <td className="text-left px-4">
          <div className="font-size-sm">
            {formatDateDayMonthYear(item.createdAt)}
          </div>
        </td>
        <td className="text-left px-4">
          <div className="font-size-sm">
            {item.blocked ? USER_STATUSES.blocked : USER_STATUSES.active}
          </div>
        </td>
        <td className="text-right">
          <Button
            disabled={processingItemId === item.id}
            className={`btn-table-action d-40 ${
              item.blocked ? 'btn-success' : 'btn-danger'
            }`}
            onClick={() => handleBlockUnblock(item._id)}>
            {processingItemId === item._id && (
              <CircularProgress color="inherit" />
            )}
            {processingItemId !== item._id &&
              (item.blocked ? ITEM_STATUSES.unblock : ITEM_STATUSES.block)}
          </Button>
        </td>
      </>
    );
  });

  const handleChangeFilter = ({ target: { name, value } }) => {
    setQuery((prev) => ({ ...prev, [QUERY_KEY[name]]: value }));
    setFilterState((prev) => ({ ...prev, [name]: value }));
  };

  const handleResetFilters = () => {
    setQuery(defaultQuery);
    setFilterState(defaultFilterState);
  };

  return (
    <WrapperSeamless>
      <div className="filter_actions_container">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            {TABLE_HEAD_TITLES.device}
          </InputLabel>
          <Select
            name={USER_FORM_NAMES.device}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterState.device}
            label={TABLE_HEAD_TITLES.device}
            onChange={handleChangeFilter}>
            <MenuItem value="">
              <em>{NONE}</em>
            </MenuItem>
            <MenuItem value={DEVICES_SELECT_VALUE_OBJ.ios}>
              {DEVICES_OBJ.ios.name}
            </MenuItem>
            <MenuItem value={DEVICES_SELECT_VALUE_OBJ.android}>
              {DEVICES_OBJ.android.name}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {USER_STATUSES.blocked}
          </InputLabel>
          <Select
            name={USER_FORM_NAMES.blocked}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterState.blocked}
            label={USER_STATUSES.blocked}
            onChange={handleChangeFilter}>
            <MenuItem value="">
              <em>{NONE}</em>
            </MenuItem>
            <MenuItem value={true}>{ACTIVATION_STATUSES.blocked}</MenuItem>
            <MenuItem value={false}>{ACTIVATION_STATUSES.unblocked}</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">{SUBSCRIPTION}</InputLabel>
          <Select
            name={USER_FORM_NAMES.subscription}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterState.subscription}
            label={SUBSCRIPTION}
            onChange={handleChangeFilter}>
            <MenuItem value="">
              <em>{NONE}</em>
            </MenuItem>
            <MenuItem value={true}>{SUBSCRIBED_STATUSES.subscribed}</MenuItem>
            <MenuItem value={false}>
              {SUBSCRIBED_STATUSES.unsubscribed}
            </MenuItem>
          </Select>
        </FormControl>
        <IconButton
          aria-label={USER_FORM_NAMES.delete}
          className={classes.margin}
          onClick={handleResetFilters}>
          <ClearIcon />
        </IconButton>
      </div>
      <Table
        noCreate={true}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.username}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.email}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.device}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.appVersion}</span>
            </th>
            {/* TEMPORART HIDDEN */}
            {/* <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.subscriptionPlan}</span>
            </th> */}
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.registered}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.status}</span>
            </th>
          </>
        }
        noDrag
        title="Items"
        items={items}
        setItems={setItems}
        total={total}
        titles
        rows={rows}
        createLink={BUOYS_CREATE_LINK}
      />
    </WrapperSeamless>
  );
}
