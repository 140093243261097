export const getError = (err) => {
  if (err && err.response && err.response.data) {
    return err.response.data.message;
  }
  if (err && err.response && err.response.statusText) {
    return err && err.response && err.response.statusText;
  }
  if (err && err.data && err.data.message) {
    return err.data.message;
  }
  if (err && err.message) {
    return err.message;
  }
  return err;
};

export const getSuccessMessage = (response) => {
  if (response && response.data && response.data.message) {
    return response.data.message;
  }

  if (response && response.message) {
    return response.message;
  }

  if (response && response.statusText) {
    return response.statusText;
  }

  return 'Operation was successful!';
};
