import { useState, useEffect, useCallback } from 'react';

export const useGetData = (Api, params) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState();

  const callback = useCallback(() => {
    if (Api) {
      setLoading(true);
      Api(params)
        .then((res) => {
          setState(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [Api, params]);

  useEffect(callback, [Api, params]);

  return [state, loading, callback, setState];
};
