export const FORM_LABELS_OBJ = {
  code: 'Code',
  title: 'Title',
  latitude: 'Latitude',
  longitude: 'Longitude'
};

export const FORM_FIELD_NAMES_OBJ = {
  code: 'code',
  title: 'title',
  latitude: 'latitude',
  longitude: 'longitude'
};

export const CUSTOM = 'custom';

export const BUOYS_QUERY_PARAMS = {
  createdAt: 'createdAt'
};

export const SORT_DIRECTIONS = {
  asc: 'asc',
  desc: 'desc'
};

export const STATION_TYPE_NAMES = {
  custom: 'Custom',
  admin: 'Admin'
};

export const STATIONS = 'Stations';

export const QUERY_KEY = {
  type: 'type'
};

export const FORM_NAMES = {
  type: 'type',
  spotType: 'Spot Type'
};
