export const QUERY_KEY = {
  device: 'device_type',
  subscription: 'in_subscription',
  blocked: 'blocked'
};

export const USER_HAS_NO_SUBSCRIPTION = 'has no subscription';

export const USER_STATUSES = {
  blocked: 'Blocked',
  active: 'Active'
};

export const ITEM_STATUSES = {
  block: 'block',
  unblock: 'unblock'
};

export const DEVICES_SELECT_VALUE_OBJ = {
  ios: 'ios',
  android: 'android'
};

export const USER_FORM_NAMES = {
  device: 'device',
  blocked: 'blocked',
  subscription: 'subscription',
  delete: 'delete'
};
