import React from 'react';

import { TabCard } from 'components/TabCard';

export default function SurveysContent() {
  return (
    <div className="pushUpUpdates_container">
      <TabCard />
    </div>
  );
}
