import React, { useState, createContext, useContext } from 'react';
export const StoreContext = createContext();

export const Store = (props) => {
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [remove, setRemove] = useState(null);
  const [user, setUser] = useState(null);
  const [lang, setLang] = useState('hy');

  return (
    <StoreContext.Provider
      value={{
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        remove,
        setRemove,
        user,
        setUser,
        lang,
        setLang
      }}>
      {props.children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export default useStore;
