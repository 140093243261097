export const DEVICES_OBJ = {
  ios: { name: 'IOS', value: '0' },
  android: { name: 'Android', value: '1' }
};

export const PRIORITIES_OBJ = {
  low: { name: 'Low', value: 'low', color: 'lightgreen' },
  medium: { name: 'Medium', value: 'mid', color: 'orange' },
  high: { name: 'High', value: 'high', color: 'red' }
};

export const defaultUpdatePriority = 0;

export const VERSION_PLACEHOLDER = 'Enter the version';

export const PRIORITY_SELECT_LABEL = 'Select the Priority';

export const SELECTED_DEVICE_LABEL = 'Selected Device:';

export const SELECT_DEVICE_DESCRIPTION =
  'You need to write the version for specific device type and save.';
