import React, { useEffect, useState } from 'react';
import * as Api from 'service';

import { Grid } from '@material-ui/core';
import { OffersImport } from 'components/OffersImport';
import StatisticsCard from 'components/StatisticsCard';
import {
  ANDROID_OFFER_CODES_CARD,
  IOS_OFFER_CODES_CARD,
  OFFER_CODES_CARD
} from './constants';
import { getError } from 'appHelpers';
import useStore from 'context';

export default function SurveysContent() {
  const { setErrorMsg } = useStore();
  const [statistic, setStatistic] = useState(null);

  const getOfferCodes = async () => {
    try {
      const { data } = await Api.offerCodes.getAll();
      setStatistic(data);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  useEffect(() => {
    getOfferCodes();
  }, []);

  return (
    <div className="pushUpUpdates_container">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <StatisticsCard
            title={OFFER_CODES_CARD.name}
            total={statistic?.allCodes}
            used={statistic?.usedCodes}
            trial={
              statistic?.androidCodesActivated + statistic?.iosCodesActivated
            }
            sRate={
              statistic?.androidRewardProvided + statistic?.iosRewardProvided
            }
          />
        </Grid>
        <Grid item xs={4}>
          <StatisticsCard
            title={ANDROID_OFFER_CODES_CARD.name}
            total={statistic?.androidCodes}
            used={statistic?.androidCodesUsed}
            trial={statistic?.androidCodesActivated}
            sRate={statistic?.androidRewardProvided}
          />
        </Grid>
        <Grid item xs={4}>
          <StatisticsCard
            title={IOS_OFFER_CODES_CARD.name}
            total={statistic?.iosCodes}
            used={statistic?.iosCodesUsed}
            trial={statistic?.iosCodesActivated}
            sRate={statistic?.iosRewardProvided}
          />
        </Grid>
        <OffersImport />
      </Grid>
    </div>
  );
}
