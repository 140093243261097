import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Card,
  Box,
  FormControlLabel,
  Switch,
  makeStyles
} from '@material-ui/core';

import * as Api from 'service';
import Save from 'components/save';
import useStore from 'context';
import { getError, getSuccessMessage } from '../../../appHelpers';
import { validationRules } from '../validators';
import { SURVEY_FORM_LABELS, UI_TEXTS_OBJ } from '../constants';
import { MainDatePicker } from 'components/MainDatePicker';
import MainSelect from 'components/MainSelect';

const useStyles = makeStyles((theme) => ({
  switchLabel: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  switch: {
    '& .MuiButtonBase-root': {
      width: '24px',
      height: '24px',
      boxShadow: 'none',
      top: '6px !important'
    }
  },
  errHelperText: {
    color: '#f83245'
  },
  selectError: {
    '& .MuiOutlinedInput-root.Mui-error': {
      '& fieldset': {
        borderColor: '#f83245'
      },
      '&:hover fieldset': {
        borderColor: '#f83245'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#f83245'
      }
    }
  }
}));

const ACTIVE_TOGGLE_LABEL = {
  true: 'Deactivate',
  false: 'Activate'
};

const initForm = {
  title: '',
  description: '',
  active: false,
  device: 'ios',
  version: '',
  expirationDate: null
};

export const CreateSurvey = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { setSuccessMsg, setErrorMsg } = useStore();
  const [formData, setFormData] = useState(initForm);
  const [isSurveyQuestionsEmpty, setIsSurveyQuestionsEmpty] = useState(true);
  const [errors, setErrors] = useState({});

  const { surveyId } = params;

  const validate = (formData) => {
    const errors = {};
    for (let field in validationRules) {
      const rule = validationRules[field];
      if (!rule.test(formData[field])) {
        errors[field] = rule.message;
      }
    }
    return errors;
  };

  const getSurveyData = async () => {
    try {
      const res = await Api.surveys.getSurveyById({ surveyId });
      const dataWithExpirationDate = {
        ...res.data,
        expirationDate: res.data.expirationDate || null
      };
      setFormData(dataWithExpirationDate);
      setIsSurveyQuestionsEmpty(!res.questionsCount);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const handleChange = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const clearExpirationDate = (e) => {
    e.stopPropagation();
    setFormData({ ...formData, expirationDate: null });
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const validationErrors = validate(formData);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length > 0) {
        return;
      }
      const res = surveyId
        ? await Api.surveys.updateSurvey(formData)
        : await Api.surveys.createSurvey(formData);
      setSuccessMsg(getSuccessMessage(res));
      history.push('/surveys');
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  useEffect(() => {
    if (surveyId) getSurveyData();
  }, []);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            label={SURVEY_FORM_LABELS.title}
            variant="outlined"
            name="title"
            className="my-2"
            fullWidth={true}
            value={formData.title}
            onChange={({ target }) => handleChange(target)}
            error={!!errors.title}
            helperText={errors.title || ''}
          />
          <TextField
            label={SURVEY_FORM_LABELS.description}
            variant="outlined"
            name="description"
            className="my-2"
            fullWidth={true}
            value={formData.description}
            onChange={({ target }) => handleChange(target)}
          />
          <MainSelect
            label={`${UI_TEXTS_OBJ.targetDevice} *`}
            name="device"
            fullWidth
            value={formData.device}
            onChange={(e) =>
              setFormData({ ...formData, device: e.target.value })
            }
            items={[
              { value: 'ios', name: UI_TEXTS_OBJ.ios },
              { value: 'android', name: UI_TEXTS_OBJ.android }
            ]}
            error={!!errors.device}
            errorText={errors.device}
          />
          <TextField
            label={SURVEY_FORM_LABELS.version}
            variant="outlined"
            name="version"
            className="my-2"
            fullWidth={true}
            value={formData.version}
            onChange={({ target }) => handleChange(target)}
            required
          />
          <MainDatePicker
            label={SURVEY_FORM_LABELS.expirationDate}
            handleChange={(date) =>
              setFormData({ ...formData, expirationDate: date })
            }
            handleClear={clearExpirationDate}
            value={formData.expirationDate}
            fullWidth
            disablePast
          />
          <FormControlLabel
            control={
              <Switch
                className={classes.switch}
                checked={formData.active}
                onChange={(e) =>
                  setFormData({ ...formData, active: e.target.checked })
                }
                disabled={isSurveyQuestionsEmpty}
              />
            }
            label={ACTIVE_TOGGLE_LABEL[formData.active]}
            className={classes.switchLabel}
          />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};
