import React from 'react';
import {
  Container,
  TextField,
  Card,
  Box,
  InputAdornment
} from '@material-ui/core';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import { useText, usePutData } from '../hooks';
import useStore from '../context';

import * as Api from '../service';
import { Save } from '../components';
import { getError } from 'appHelpers';

const initForm = {
  old_password: '',
  password: '',
  password_confirmation: ''
};

const Form = () => {
  const updateCallback = usePutData(Api.main.updatePassword);
  const { setErrorMsg } = useStore();

  const [text, setText, , input] = useText(initForm);

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      await updateCallback(text);
      setText(initForm);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              )
            }}
            type="password"
            {...input('old_password')}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              )
            }}
            type="password"
            {...input('password')}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              )
            }}
            type="password"
            {...input('password_confirmation')}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
