export const RESPONSE_STATUS_CODES = {
  incorrectData: 422,
  unAuthorized: 401,
  notFound: 404,
  serverError: 500
};

export const LANGUAGES = [
  { value: 'ru', name: 'Russian' },
  { value: 'en', name: 'English' },
  { value: 'es', name: 'Spanish' },
  { value: 'fr', name: 'French' },
  { value: 'de', name: 'German' },
  { value: 'it', name: 'Italian' },
  { value: 'ko', name: 'Korean' },
  { value: 'ja', name: 'Japanese' },
  { value: 'pt-PT', name: 'Portuguese (Portugal)' },
  { value: 'pt-BR', name: 'Portuguese (Brazil)' },
  { value: 'es-419', name: 'Spanish (Latin America and Caribbean)' }
];

export const APP_TITLE = 'Buoy’s Weather';

export const TEXTFIELD_VARIANTS = {
  filled: 'filled'
};

export const BUTTON_VARIANTS = {
  contained: 'contained'
};

export const BTN_TEXTS = {
  save: 'Save',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  remove: 'Remove'
};

export const TABLE_HEAD_TITLES = {
  code: 'Code',
  title: 'Title',
  actions: 'Actions',
  latitude: 'Latitude',
  longitude: 'Longitude',
  name: 'Name',
  email: 'Email',
  stationName: 'Station Name',
  stationId: 'Station Id',
  device: 'Device',
  status: 'Status',
  subscriptionPlan: 'Subscription Plan',
  username: 'Username',
  createdAt: 'Created',
  version: 'Version',
  page: 'Page',
  platform: 'Platform',
  registered: 'Registered',
  appVersion: 'App Version',
  type: 'Type'
};

export const GUEST = 'Guest';

export const USER_STATUS_NAMES = {
  active: 'active',
  inActive: 'inactive'
};

export const BUTTON_TEXTS = {
  block: 'Block',
  unblock: 'Unblock'
};

export const NONE = 'None';

export const ACTIVATION_STATUSES = {
  blocked: 'Blocked',
  unblocked: 'Unblocked'
};

export const SUBSCRIBED_STATUSES = {
  subscribed: 'Subscribed',
  unsubscribed: 'Unsubscribed'
};

export const SUBSCRIPTION = 'Subscription';

export const BUOYS_CREATE_LINK = 'buoys/create';

export const APP_IDS = {
  iosAppIds: [
    'com.nextstack.buoysweather',
    'com.nextstack.buoysweather-premium'
  ],
  androidAppIds: [
    'buoysweather.nextstack.com.buoysweather',
    'buoysweather.nextstack.com.buoysweather.premium'
  ]
};

export const WARNING = 'Warning';
export const DIALOG_TITLES = {
  removeWarning: 'Are you sure you want to delete this entry?'
};

export const DIALOG_DESCRIPTION = {
  cannotUndo: 'You cannot undo this operation.',
  surveyWillBecomeInactive:
    'If you remove the last question the survey will become INACTIVE!'
};
