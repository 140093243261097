export const SURVEY_LIST_PAGE_OBJ = {
  title: 'Surveys',
  createNew: 'Create new survey'
};

export const SURVEY_ACTIVATION_STATES = {
  active: 'active',
  inactive: 'inactive'
};

export const VALIDATION_MESSAGES = {
  requiredQuestionTitle: 'Question title is required',
  requiredAnswerOption: 'This answer option is required'
};

export const QUESTION_TYPES_OBJ = {
  multi: 'multi',
  text: 'text'
};

export const UI_TEXTS_OBJ = {
  survey: 'Survey',
  addNewQuestion: 'Add a New Question',
  type: 'Type',
  multiChoice: 'Multi Choice',
  customTextWrite: ' Custom Text Write',
  addOption: 'Add Option',
  addQuestion: 'Add Question',
  cancel: 'Cancel',
  remove: 'Remove',
  confirmRemoval: 'Confirm Removal',
  areSureRemove: 'Are you sure you want to remove this question?',
  targetDevice: 'Target Device',
  ios: 'iOS',
  android: 'Android',
  expired: 'Expired At',
  expires: 'Expires At',
  spotType: 'Spot Type'
};

export const SURVEY_FORM_LABELS = {
  title: 'Survey Title',
  description: 'Description',
  version: 'Release Version',
  expirationDate: 'Expiration Date'
};
