export const STATION_TYPES_LIST_PAGE_OBJ = {
  title: 'Spot Types',
  createNew: 'Create new type'
};

export const DEFAULT_STATION_TYPE = 'Buoys';

export const UI_TEXTS_OBJ = {
  survey: 'Survey',
  addNewQuestion: 'Add a New Question',
  type: 'Type',
  multiChoice: 'Multi Choice',
  customTextWrite: ' Custom Text Write',
  addOption: 'Add Option',
  addQuestion: 'Add Question',
  cancel: 'Cancel',
  remove: 'Remove',
  confirmRemoval: 'Confirm Removal',
  areSureRemove: 'Are you sure you want to remove this question?',
  targetDevice: 'Target Device',
  ios: 'iOS',
  android: 'Android',
  createdAt: 'Created At',
  spotType: 'Spot Type',
  confirmDialogContent: `The stations with this type will be changed to type: "${DEFAULT_STATION_TYPE}"`,
  default: 'default'
};

export const STATION_TYPES_FORM_LABELS = {
  name: 'Spot Type Name'
};
