import queryString from 'query-string';

class Base {
  getRequest(uri, query) {
    return this.baseRequest('GET', uri, null, query);
  }
  postRequest(...rest) {
    return this.baseRequest('POST', ...rest);
  }
  deleteRequest(...rest) {
    return this.baseRequest('DELETE', ...rest);
  }
  putRequest(...rest) {
    return this.baseRequest('PUT', ...rest);
  }

  postFormDataRequest(uri, formData, query = null) {
    return this.baseRequest('POST', uri, formData, query, true);
  }

  async baseRequest(type, uri, data = null, query = null, isFormData = false) {
    const queryUrl = query
      ? `?${queryString.stringify(query, {
          skipEmptyString: true,
          skipNull: true
        })}`
      : '';

    const fullUrl = process.env.REACT_APP_apiBaseUrl + uri + queryUrl;

    let headers = {
      Authorization: sessionStorage.token ?? localStorage.token
    };

    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
    }

    const res = await fetch(fullUrl, {
      method: type,
      headers: headers,
      body:
        isFormData || data instanceof Blob
          ? data
          : data
          ? JSON.stringify(data)
          : null
    });

    if (!res.ok) {
      const err = await res.json();
      // eslint-disable-next-line no-throw-literal
      throw {
        status: res.status,
        message: err.message || 'something went wrong',
        data: err
      };
    }

    const isJson = res.headers
      .get('Content-Type')
      ?.includes('application/json');

    return isJson ? res.json() : true;
  }
}

export default Base;
