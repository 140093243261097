import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import { LANGUAGES } from 'constants.js';

export const LanguageSelect = ({
  selectedLanguage,
  handleSelectLanguage,
  error,
  helperText,
  smallWidth
}) => {
  const handleSelect = ({ target }) => {
    handleSelectLanguage(target);
  };

  const selectStyle = {
    width: smallWidth ? '200px' : 'auto'
  };

  return (
    <FormControl fullWidth={!smallWidth} margin="normal" error={error}>
      <InputLabel id="language-select-label">Select Language</InputLabel>
      <Select
        name="language"
        labelId="language-select-label"
        id="language-select"
        value={selectedLanguage}
        onChange={handleSelect}
        style={selectStyle} // Apply the style object here
      >
        {LANGUAGES.map((language) => (
          <MenuItem key={language.value} value={language.value}>
            {language.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
