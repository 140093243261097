import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import { arrayMove } from '@dnd-kit/sortable';
import {
  Button,
  Container,
  Card,
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';

import * as Api from 'service';
import { useGetData } from 'hooks';
import { CreateChangelogItemDialog } from './CreateChangelogItemDialog';
import { LanguageSelect } from 'components/LanguageSelect';
import { ChangelogItem } from './ChangelogItem';
import Save from 'components/save';
import { getError, getSuccessMessage } from 'appHelpers';
import useStore from 'context';
import { ConfirmDialog } from 'components/ConfirmDialog';
import {
  CHANGELOG_DETAIL_FIELD_NAMES,
  CREATE_NEW_ITEM,
  PAGE_TITLE
} from '../constants';
import { BTN_TEXTS } from 'constants.js';

const useStyles = makeStyles(() => ({
  pageTitleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pageTitle: {
    fontSize: 40
  },
  content: {
    maxWidth: 'unset'
  },
  titleContainer: {
    display: 'flex',
    gap: '20px'
  },
  itemsContainer: {
    display: 'flex',
    gap: '40px',
    overflowX: 'auto',
    padding: 20
  },
  changelogItem: {},
  addButton: {
    marginLeft: 'auto',
    fontWeight: 700,
    fontSize: 18
  },
  bolded: {
    fontWeight: 900
  }
}));

const adaptChangelog = (changelog) => {
  const newItems = [...changelog.items];
  newItems.sort((a, b) => a.order - b.order);
  return { ...changelog, items: newItems };
};

export const ViewChangelog = () => {
  const { setErrorMsg, setSuccessMsg } = useStore();
  const classes = useStyles();
  const { id } = useParams();
  const [lang, setLang] = useState('en');
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);
  const [parameters, setParameters] = useState({ id, lang });
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(true);
  const [data, , getChangelogData] = useGetData(
    id && Api.changelog.getChangelogById,
    parameters
  );
  const [changelog, setChangelog] = useState(
    data?.data ? adaptChangelog(data.data) : {}
  );
  const [updatingItem, setUpdatingItem] = useState(null);

  const deletingItemRef = useRef();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event) => {
    setIsTransitionEnabled(false);
    const { active, over } = event;

    if (active.id !== over.id) {
      setChangelog((prev) => {
        const items = prev.items;
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        if (oldIndex !== newIndex) setIsOrderChanged(true);

        return { ...prev, items: arrayMove(items, oldIndex, newIndex) };
      });
    }
  };

  const handleDragStart = () => setIsTransitionEnabled(true);

  const handleOpenDialog = (item = null) => {
    setUpdatingItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setUpdatingItem(null);
    setOpenDialog(false);
  };

  const handleOpenRemoveDialog = (id) => {
    deletingItemRef.current = id;
    setIsOpenRemoveDialog(true);
  };

  const handleCloseRemoveDialogue = () => setIsOpenRemoveDialog(false);

  const handleSelectLanguage = ({ value }) => {
    setLang(value);
    setParameters({ ...parameters, lang: value });
  };

  const handleRemoveItem = async () => {
    try {
      const res = await Api.changelog.deleteChangelogItem(
        deletingItemRef.current
      );
      setSuccessMsg(getSuccessMessage(res));
      getChangelogData();
      handleCloseRemoveDialogue();
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const handleSaveOrder = (e) => {
    e.preventDefault();
    try {
      const savingItems = changelog.items.map((item, idx) => ({
        ...item,
        order: idx + 1
      }));
      const res = Api.changelog.updateChangelogItemsOrder(
        { items: savingItems, lang },
        id
      );
      setSuccessMsg(getSuccessMessage(res));
      setIsOrderChanged(false);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  useEffect(() => {
    if (data?.data) setChangelog(adaptChangelog(data.data));
  }, [data]);

  return (
    <Container className={classes.content}>
      <Card className="shadow-xxl px-4 py-2">
        <Box className={classes.pageTitleBlock}>
          <Typography className={classes.pageTitle} variant="h6" gutterBottom>
            {PAGE_TITLE}
          </Typography>
          <Button
            className={classes.addButton}
            onClick={() => handleOpenDialog()}
            variant="contained"
            color="primary">
            {CREATE_NEW_ITEM}
          </Button>
        </Box>
        <Box className={classes.titleContainer}>
          <Typography>
            <span className={classes.bolded}>
              {CHANGELOG_DETAIL_FIELD_NAMES.platform}
            </span>{' '}
            - {changelog?.platform}
          </Typography>
          <Typography>
            <span className={classes.bolded}>
              {CHANGELOG_DETAIL_FIELD_NAMES.version}
            </span>{' '}
            - {changelog?.version}
          </Typography>
          <Typography>
            <span className={classes.bolded}>
              {CHANGELOG_DETAIL_FIELD_NAMES.page}
            </span>{' '}
            - {changelog?.slug}
          </Typography>
        </Box>
        <LanguageSelect
          smallWidth
          selectedLanguage={lang}
          handleSelectLanguage={handleSelectLanguage}
        />
        <CreateChangelogItemDialog
          updatingItem={updatingItem}
          open={openDialog}
          handleClose={handleCloseDialog}
          getChangelogData={getChangelogData}
          lang={lang}
          order={
            changelog?.items?.length
              ? changelog.items.slice(-1)[0].order + 1
              : 1
          }
        />
        <ConfirmDialog
          open={isOpenRemoveDialog}
          handleClose={handleCloseRemoveDialogue}
          handleConfirm={handleRemoveItem}
          confirmBtnText={BTN_TEXTS.remove}
        />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}>
          <SortableContext
            items={changelog?.items || []}
            strategy={horizontalListSortingStrategy}>
            <Box className={classes.itemsContainer}>
              {changelog?.items &&
                changelog.items.map((item, idx) => (
                  <ChangelogItem
                    handleOpenDialog={handleOpenDialog}
                    handleOpenRemoveDialog={handleOpenRemoveDialog}
                    isTransitionEnabled={isTransitionEnabled}
                    key={idx}
                    item={item}
                    id={item.id}
                  />
                ))}
            </Box>
          </SortableContext>
        </DndContext>
        <Save disabled={!isOrderChanged} onClick={handleSaveOrder} />
      </Card>
    </Container>
  );
};
