// MainSelect.js
import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  makeStyles,
  FormHelperText
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 140,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  selectElm: {
    width: '100%',
    minWidth: 120
  },
  errorHelperText: {
    color: '#f83245'
  }
}));

const MainSelect = ({
  label,
  value,
  onChange,
  items,
  name,
  hasReset,
  resetHandler,
  error,
  errorText,
  fullWidth,
  className
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={`${className} ${classes.formControl} ${
        error ? classes.selectError : ''
      }`}
      error={error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        fullWidth={fullWidth}
        labelId={`${name}-label`}
        id={`${name}-select`}
        name={name}
        value={value}
        className={classes.selectElm}
        onChange={onChange}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}>
        {items.map((item, idx) => (
          <MenuItem key={idx} value={item._id || item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {hasReset && (
        <IconButton
          aria-label="reset"
          onClick={resetHandler}
          className={classes.margin}>
          <ClearIcon />
        </IconButton>
      )}
      {error && (
        <FormHelperText className={classes.errorHelperText}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MainSelect;
