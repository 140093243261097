import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Button, List, ListItem, Divider } from '@material-ui/core';

import * as Api from '../../service';
import useStore from '../../context';
import { APP_TITLE } from '../../constants';

import Logo from 'assets/images/icons/icon.svg';

const HeaderUserbox = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  const { setUser, user } = useStore();

  const logOff = async () => {
    await Api.main.logOff();
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    setUser(null);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple>
        <div className="d-block p-0 avatar-icon-wrapper">
          <div className="avatar-icon rounded">
            <img src={Logo} alt={APP_TITLE} />
          </div>
        </div>

        <div className="d-none d-xl-block pl-2">
          <div className="font-weight-bold pt-2 line-height-1">{user.name}</div>
          <span className="text-black-50">{user.email}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}>
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <List
            component="div"
            className="nav-neutral-primary text-left d-flex align-items-center flex-column px-3 pb-3">
            <ListItem
              button
              className="d-block text-left"
              onClick={() => {
                handleClose();
                history.push('/password-change');
              }}>
              Change password
            </ListItem>
            <ListItem button className="d-block text-left" onClick={logOff}>
              Log out
            </ListItem>
          </List>
          <Divider className="w-100" />
        </div>
      </Menu>
    </>
  );
};

export default HeaderUserbox;
