import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';

import * as Api from 'service';
import useStore from 'context';
import { getError, getSuccessMessage } from 'appHelpers';
import { MenuItem } from 'react-contextmenu';

import removeRedIcon from 'assets/images/icons/remove_red.png';
import {
  QUESTION_TYPES_OBJ,
  UI_TEXTS_OBJ,
  VALIDATION_MESSAGES
} from '../constants';
import {
  BTN_TEXTS,
  DIALOG_DESCRIPTION,
  RESPONSE_STATUS_CODES
} from 'constants.js';
import { useDialog } from 'hooks';
import { ConfirmDialog } from 'components/ConfirmDialog';

export const SurveyView = () => {
  const params = useParams();
  const { setSuccessMsg, setErrorMsg } = useStore();
  const [survey, setSurvey] = useState({});
  const [questions, setQuestions] = useState([]);
  const [questionText, setQuestionText] = useState('');
  const [questionType, setQuestionType] = useState(QUESTION_TYPES_OBJ.text);
  const [options, setOptions] = useState(['']);
  const [openModal, setOpenModal] = useState(false);
  const questionRemoveDialog = useDialog();

  const [errors, setErrors] = useState({});

  const { surveyId } = params;

  const validateForm = () => {
    const errorObj = {};

    if (!questionText.trim()) {
      errorObj.questionText = VALIDATION_MESSAGES.requiredQuestionTitle;
    }

    if (questionType === QUESTION_TYPES_OBJ.multi) {
      const optionErrors = options.map((option) =>
        option.trim() === '' ? VALIDATION_MESSAGES.requiredAnswerOption : null
      );

      if (optionErrors.some((error) => error !== null)) {
        errorObj.options = optionErrors;
      }
    }

    return errorObj;
  };

  const getSurveyDetails = async () => {
    try {
      const { data } = await Api.surveys.getSurveyById({ surveyId });
      setSurvey(data);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const addOption = () => {
    setOptions([...options, '']);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const fetchQuestions = async () => {
    try {
      const { data } = await Api.surveys.getQuestionsBySurveyId({ surveyId });
      setQuestions(data);
    } catch (err) {
      if (err.status !== RESPONSE_STATUS_CODES.notFound)
        setErrorMsg(getError(err));
    }
  };

  const submitQuestion = async () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const res = await Api.surveys.addQuestion({
        surveyId,
        title: questionText,
        type: questionType,
        answerOptions:
          questionType === QUESTION_TYPES_OBJ.multi ? options : null
      });
      setSuccessMsg(getSuccessMessage(res));
      setOpenModal(false);
      setQuestionText('');
      setQuestionType(QUESTION_TYPES_OBJ.text);
      setOptions(['']);
      fetchQuestions();
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenRemoveDialogue = (index) =>
    questionRemoveDialog.openDialog({ index });

  const handleCloseRemoveDialogue = () => questionRemoveDialog.closeDialog();

  const handleCloseModal = () => {
    setOpenModal(false);
    setQuestionText('');
    setQuestionType(QUESTION_TYPES_OBJ.text);
    setOptions(['']);
  };

  const handleRemoveQuestion = async () => {
    try {
      const res = await Api.surveys.removeQuestion({
        questionId: questionRemoveDialog.dialogData.index
      });
      setSuccessMsg(getSuccessMessage(res));
      handleCloseRemoveDialogue();
      fetchQuestions();
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  useEffect(() => {
    if (surveyId) {
      getSurveyDetails();
      fetchQuestions();
    }
    return () => {
      setQuestionType(QUESTION_TYPES_OBJ.text);
      setQuestionText('');
    };
  }, [surveyId]);

  return (
    <div className="survey_view_container">
      <h2>
        {UI_TEXTS_OBJ.survey} - {survey.title}
      </h2>
      <div className="questions_container">
        {questions.map((question, index) => (
          <div key={index} className="question">
            <img
              className="remove_icon"
              alt="remove"
              src={removeRedIcon}
              onClick={() => handleOpenRemoveDialogue(question._id)}
            />
            <h6 className="question_text">{question.title}</h6>
            {question.type === QUESTION_TYPES_OBJ.multi && (
              <ul className="answers_list_block">
                {question.answerOptions.map((option, aIdx) => (
                  <li key={aIdx} className="answer_item">
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}

        <Button
          className="add_question_btn"
          variant="outlined"
          onClick={handleOpenModal}>
          +
        </Button>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>{UI_TEXTS_OBJ.addNewQuestion}</DialogTitle>
        <DialogContent>
          <TextField
            label="Question Text"
            variant="outlined"
            fullWidth
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
            required
            error={!!errors.questionText}
            helperText={errors.questionText}
          />

          <FormControl variant="outlined" className="my-2" fullWidth>
            <InputLabel>{UI_TEXTS_OBJ.type}</InputLabel>
            <Select
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
              label="Type"
              required>
              <MenuItem className="menu_item" value="multi">
                {UI_TEXTS_OBJ.multiChoice}
              </MenuItem>
              <MenuItem className="menu_item" value="text">
                {UI_TEXTS_OBJ.customTextWrite}
              </MenuItem>
            </Select>
          </FormControl>

          {questionType === QUESTION_TYPES_OBJ.multi && (
            <div>
              {options.map((option, index) => (
                <div key={index} className="option_input">
                  <TextField
                    label={`Option ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    required
                    error={!!(errors.options && errors.options[index])}
                    helperText={errors.options && errors.options[index]}
                  />
                </div>
              ))}
              <Button color="primary" onClick={addOption}>
                {UI_TEXTS_OBJ.addOption}
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={submitQuestion} color="primary">
            {UI_TEXTS_OBJ.addQuestion}
          </Button>
          <Button onClick={handleCloseModal} color="secondary">
            {UI_TEXTS_OBJ.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={questionRemoveDialog.isOpen}
        handleClose={handleCloseRemoveDialogue}
        handleConfirm={handleRemoveQuestion}
        content={
          questions.length === 1 && survey.active
            ? DIALOG_DESCRIPTION.surveyWillBecomeInactive
            : undefined
        }
        confirmBtnText={BTN_TEXTS.remove}
      />
    </div>
  );
};
