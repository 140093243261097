export const DEVICES_OBJ = {
  ios: { name: 'IOS', value: '0' },
  android: { name: 'Android', value: '1' }
};

export const defaultUpdatePriority = 0;

export const SELECTED_DEVICE_LABEL = 'Selected Device:';
export const IMPORT_DOCUMENT_TEXT = 'Import csv document to fill data.';

export const DRAGN_DROP_FILE =
  "Drag 'n' drop a file here, or click to select one";
