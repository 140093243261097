import Base from './base';

class Main extends Base {
  login = (data) => this.postRequest('/admin/auth/login', data);
  logOff = () => this.getRequest('/admin/auth/logoff');
  getuser = () => this.getRequest('/admin/auth/getUser');
  updatePassword = (data) =>
    this.putRequest('/admin/auth/change-password', data);
  uploadFile = (file, path) =>
    this.postRequest(`/files/write`, file, { name: file.name, path });
  importFile = (formData, platform) =>
    this.postFormDataRequest(`/admin/offer/codes/${platform}`, formData);
}

export default new Main();
