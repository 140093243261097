import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import removeRedIcon from 'assets/images/icons/remove_red.png';
import editImg from 'assets/images/edit-photo/edit_light.svg';

const imageBaseUrl = process.env.REACT_APP_imagesBaseUrl;

const useStyles = makeStyles(() => ({
  changelogItemCard: {
    position: 'relative',
    backgroundColor: 'white',
    width: 257,
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '24px 24px 0 0',
    boxShadow: '0px 3px 6px #00000029',
    padding: '46px 24px',
    overflow: 'hidden',
    flexShrink: 0
  },
  contentBlock: {
    width: '100%',
    height: '100%',
    cursor: 'grab'
  },
  iconsBlock: {
    position: 'absolute',
    top: 10,
    right: 4,
    display: 'flex',
    gap: '8px'
  },
  actionIcon: {
    width: 20,
    cursor: 'pointer',
    zIndex: 999
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: 200
  },
  title: {
    fontWeight: 'bold',
    margin: '16px 0 8px 0',
    alignSelf: 'baseline'
  },
  description: {
    alignSelf: 'baseline'
  }
}));

export const ChangelogItem = ({
  item,
  id,
  isTransitionEnabled,
  handleOpenRemoveDialog,
  handleOpenDialog
}) => {
  const classes = useStyles();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isTransitionEnabled ? transition : 'none'
  };

  return (
    <Box ref={setNodeRef} style={style} className={classes.changelogItemCard}>
      <div className={classes.iconsBlock}>
        <img
          onClick={() => handleOpenDialog(item)}
          className={classes.actionIcon}
          src={editImg}
          alt="Edit"
        />
        <div onClick={() => handleOpenRemoveDialog(id)}>
          <img
            className={`remove_icon ${classes.actionIcon}`}
            alt="remove"
            src={removeRedIcon}
          />
        </div>
      </div>
      <div className={classes.contentBlock} {...attributes} {...listeners}>
        <img
          src={`${imageBaseUrl}${item.media}`}
          alt={item.title}
          className={classes.image}
        />
        <Typography variant="h6" className={classes.title}>
          {item.title}
        </Typography>
        <Typography className={classes.description}>
          {item.description}
        </Typography>
      </div>
    </Box>
  );
};
