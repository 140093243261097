export const PAGE_TITLE = 'Survey Details';

export const FORM_LABELS_OBJ = {
  app: 'App',
  version: 'Version',
  page: 'Page'
};

export const CREATE_NEW_ITEM = 'Create New Item';
export const ADD_NEW_ITEM = 'Add New Changelog Item';
export const CHANGE_PICTURE = 'Change Picture';
export const UPLOAD_PICTURE = 'Upload Picture';

export const CHANGELOG_DETAIL_FIELD_NAMES = {
  version: 'Version',
  platform: 'Platform',
  page: 'Page'
};

export const FORM_FIELD_NAMES_OBJ = {
  app: 'app',
  version: 'version',
  page: 'page'
};

export const CUSTOM = 'custom';

export const BUOYS_QUERY_PARAMS = {
  createdAt: 'createdAt'
};

export const SORT_DIRECTIONS = {
  asc: 'asc',
  desc: 'desc'
};

export const APP_IDS = [
  { value: 'com.nextstack.buoysweather', name: 'iOS' },
  { value: 'com.nextstack.buoysweather-premium', name: 'iOS Pro' },
  {
    value: 'buoysweather.nextstack.com.buoysweather',
    name: 'Android'
  },
  {
    value: 'buoysweather.nextstack.com.buoysweather.premium',
    name: 'Android Pro'
  }
];

export const PLATFORM_BY_APP_ID = {
  'com.nextstack.buoysweather': 'iOS',
  'com.nextstack.buoysweather-premium': 'iOS Pro',
  'buoysweather.nextstack.com.buoysweather': 'Android',
  'buoysweather.nextstack.com.buoysweather.premium': 'Android Pro'
};

export const APP_PAGES = [
  'Home',
  'Map',
  'Favorite',
  'Profile',
  'Settings',
  'Widget',
  'Premium',
  'Overview',
  'Weather',
  'Wind',
  'Wave',
  'StationDetail'
];

export const CHANGELOG_ITEM_FIELD_KEYS = {
  title: 'title',
  description: 'description',
  media: 'media',
  order: 'order',
  changelogId: 'changelogId',
  image: 'image',
  lang: 'lang'
};

export const CHANGELOG_ITEM_FIELD_LABELS = {
  title: 'Title',
  description: 'Description',
  image: 'Image',
  lang: 'Language'
};
