import React, { Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './pages/login';
import { LeftSidebar } from './layout-blueprints';
import useStore from './context';
import * as Api from './service';
import { SuspenseLoading } from 'components';
import {
  PasswordChangeForm,
  BuoysForm,
  Buoys,
  StationSuggestions,
  Surveys,
  PushUpUpdates,
  Offers,
  Users
} from './pages';
import { CreateSurvey } from 'pages/surveys/create';
import { SurveyView } from 'pages/surveys/view';
import { WhatsNewList } from 'pages/whatsNew';
import { CreateChangelog } from 'pages/whatsNew/components/CreateChangeLog';
import { ViewChangelog } from 'pages/whatsNew/components/ViewChangelog';
import { StationTypes } from 'pages/station-types';
import { CreateStationType } from 'pages/station-types/create';

const Routes = () => {
  const [show, setShow] = useState(false);
  const { user, setUser } = useStore();

  useEffect(() => {
    if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
      Api.main
        .getuser()
        .then((res) => {
          setUser(res.user);
          setShow(true);
        })
        .catch((e) => {
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');
          setShow(true);
        });
    } else {
      setShow(true);
    }
  }, [setUser]);

  return (
    show && (
      <Suspense fallback={<SuspenseLoading />}>
        {!user ? (
          <Login />
        ) : (
          <LeftSidebar>
            <Switch>
              <Route
                exact
                path="/password-change"
                component={PasswordChangeForm}
              />
              <Route exact path="/buoys/create/:id?" component={BuoysForm} />
              <Route exact path="/buoys/admin" component={Buoys} />
              <Route exact path="/buoys/custom" component={Buoys} />
              <Route exact path="/">
                <Redirect to="/buoys/admin" />
              </Route>
              <Route exact path="/stations" component={StationSuggestions} />
              <Route exact path="/station-types" component={StationTypes} />
              <Route
                exact
                path="/station-types/create"
                component={CreateStationType}
              />
              <Route
                exact
                path="/station-types/:typeId/edit"
                component={CreateStationType}
              />
              <Route exact path="/surveys" component={Surveys} />
              <Route exact path="/surveys/create" component={CreateSurvey} />
              <Route
                exact
                path="/surveys/:surveyId/edit"
                component={CreateSurvey}
              />
              <Route exact path="/surveys/:surveyId" component={SurveyView} />
              <Route exact path="/push-up-updates" component={PushUpUpdates} />
              <Route exact path="/offers" component={Offers} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/what-is-new/list" component={WhatsNewList} />
              <Route
                exact
                path="/what-is-new/create"
                component={CreateChangelog}
              />
              <Route
                exact
                path="/what-is-new/edit/:id"
                component={CreateChangelog}
              />
              <Route
                exact
                path="/what-is-new/view/:id"
                component={ViewChangelog}
              />
            </Switch>
          </LeftSidebar>
        )}
      </Suspense>
    )
  );
};

export default Routes;
