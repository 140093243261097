import React from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DateRangeIcon from '@material-ui/icons/DateRange';

export const MainDatePicker = ({
  label,
  handleChange,
  handleClear,
  format = 'MM/dd/yyyy',
  value,
  fullWidth,
  disablePast
}) => {
  const today = new Date();
  return (
    <MuiDatePicker
      label={label}
      inputVariant="outlined"
      fullWidth={fullWidth}
      format={format}
      value={value}
      onChange={handleChange}
      minDate={disablePast ? today : undefined}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={(e) => value && handleClear(e)}>
              {value ? <ClearIcon /> : <DateRangeIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};
