import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useGetList = (Api, query) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const [total, setTotal] = useState(null);

  const queryUrl = new URLSearchParams(useLocation().search);

  const callback = useCallback(() => {
    if (Api) {
      setLoading(true);
      Api({
        page: (queryUrl.get('page') || '1') - 1,
        search: queryUrl.get('search'),
        limit: 10,
        ...(query && query)
      })
        .then((res) => {
          setItems(res.items || res?.data?.data || res?.data);
          setTotal(res.total || res?.data?.total);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [Api, query, queryUrl]);

  useEffect(callback, [Api, queryUrl.toString(), query]);

  return [items, total, callback, setItems, loading];
};
