import React, { memo } from 'react';
import { List, ListItem } from '@material-ui/core';
import { FlagIcon } from 'react-flag-kit';
import useStore from '../context'

const Lang = () => {
    const { lang, setLang } = useStore()

    return (
        <List component="div" className="my-3 nav-tabs nav-tabs-primary d-flex" >
            <ListItem
                button
                disableRipple
                selected={lang === 'hy'}
                onClick={() => setLang('hy')}>
                <FlagIcon code="AM" size={44} />
                <div className="divider" />
            </ListItem>
            <ListItem
                button
                disableRipple
                selected={lang === 'en'}
                onClick={() => setLang('en')}>
                <FlagIcon code="US" size={44} />
                <div className="divider" />
            </ListItem>
        </List >
    )
};


export default memo(Lang);