import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, TextField, Card, Box } from '@material-ui/core';

import * as Api from 'service';
import Save from 'components/save';
import useStore from 'context';
import { getError, getSuccessMessage } from 'appHelpers';
import { validationRules } from '../validators';
import { STATION_TYPES_FORM_LABELS } from '../constants';

const initForm = {
  name: ''
};

export const CreateStationType = () => {
  const history = useHistory();
  const params = useParams();
  const { setSuccessMsg, setErrorMsg } = useStore();
  const [formData, setFormData] = useState(initForm);
  const [errors, setErrors] = useState({});

  const { typeId } = params;

  const validate = (formData) => {
    const errors = {};
    for (let field in validationRules) {
      const rule = validationRules[field];
      if (!rule.test(formData[field])) {
        errors[field] = rule.message;
      }
    }
    return errors;
  };

  const getStationType = async () => {
    try {
      const res = await Api.spotTypes.getById(typeId);

      setFormData(res.data);
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const handleChange = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const validationErrors = validate(formData);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length > 0) {
        return;
      }
      const res = typeId
        ? await Api.spotTypes.updateById(typeId, formData)
        : await Api.spotTypes.createNew(formData);
      setSuccessMsg(getSuccessMessage(res));
      history.push('/station-types');
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  useEffect(() => {
    if (typeId) getStationType();
  }, []);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            label={`${STATION_TYPES_FORM_LABELS.name}*`}
            variant="outlined"
            name="name"
            className="my-2 capitalize"
            fullWidth={true}
            value={formData.name}
            onChange={({ target }) => handleChange(target)}
            error={!!errors.name}
            helperText={errors.name || ''}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};
