import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles
} from '@material-ui/core';
import {
  BTN_TEXTS,
  DIALOG_DESCRIPTION,
  DIALOG_TITLES,
  WARNING
} from 'constants.js';

const useStyles = makeStyles((theme) => ({
  dangerColor: {
    color: theme.palette.error.main
  }
}));

export const ConfirmDialog = ({
  open,
  handleClose,
  handleConfirm,
  title = DIALOG_TITLES.removeWarning,
  content = DIALOG_DESCRIPTION.cannotUndo,
  confirmBtnText = BTN_TEXTS.confirm
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <span className={classes.dangerColor}>{WARNING}: </span>
        {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {BTN_TEXTS.cancel}
        </Button>
        <Button
          className={classes.dangerColor}
          onClick={handleConfirm}
          color="primary">
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
