import Resource from './resource';
export const imgUrl = process.env.REACT_APP_imagesBaseUrl;
export { default as main } from './main';

export const buoys = new Resource('/admin/buoys');
export const spotTypes = new Resource('/admin/spot-types');
export const surveys = new Resource('/v1/survey');
export const changelog = new Resource('/admin/changelog');
export const stationSuggestions = new Resource('/admin/station-suggestions');
export const users = new Resource('/admin/users');
export const adminApplication = new Resource('/admin/application');
export const offerCodes = new Resource('/admin/offer/codes');
