import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  TextField,
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles
} from '@material-ui/core';

import { useText, useGetData } from 'hooks';
import * as Api from 'service';
import { Save } from 'components';
import { validationRules } from '../validators';
import {
  APP_IDS,
  FORM_FIELD_NAMES_OBJ,
  FORM_LABELS_OBJ,
  APP_PAGES,
  PLATFORM_BY_APP_ID
} from '../constants';
import { getError, getSuccessMessage } from 'appHelpers';
import useStore from 'context';
import { RESPONSE_STATUS_CODES } from 'constants.js';

const useStyles = makeStyles(() => ({
  errorOutline: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f83245'
    },
    '& .MuiInputLabel-outlined.Mui-error': {
      color: '#f83245'
    },
    '& .MuiFormHelperText-contained.Mui-error': {
      color: '#f83245'
    }
  },
  errHelperText: {
    color: '#f83245'
  },
  selectError: {
    '& .MuiOutlinedInput-root.Mui-error': {
      '& fieldset': {
        borderColor: '#f83245'
      },
      '&:hover fieldset': {
        borderColor: '#f83245'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#f83245'
      }
    }
  }
}));

const initForm = {
  appId: '',
  version: '',
  slug: ''
};

export const CreateChangelog = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const params = { id };
  const [data] = useGetData(id && Api.changelog.getChangelogById, params);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(data || initForm);
  const { setSuccessMsg, setErrorMsg } = useStore();
  const resetFieldError = ({ target }) =>
    setErrors((prev) => ({ ...prev, [target.name]: null }));
  const [, , , input] = useText(data || initForm, resetFieldError);

  const validate = (data) => {
    const errorMessages = {};

    for (let field in validationRules) {
      const rules = Array.isArray(validationRules[field])
        ? validationRules[field]
        : [validationRules[field]];
      for (let rule of rules) {
        if (
          !rule.test(
            typeof data[field] === 'string' ? data[field].trim() : data[field]
          )
        ) {
          errorMessages[field] = rule.message;
          break;
        }
      }
    }

    return errorMessages;
  };

  const handleSelect = ({ target }) => {
    setFormData((prev) => ({
      ...prev,
      [target.name]: target.value,
      platform: PLATFORM_BY_APP_ID[target.value] || prev.platform
    }));
    setErrors((prev) => ({ ...prev, [target.name]: null }));
  };

  const handleChange = ({ target }) => {
    setFormData((prev) => ({ ...prev, [target.name]: target.value }));
    setErrors((prev) => ({ ...prev, [target.name]: null }));
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const validationErrors = validate(formData);
      if (Object.keys(validationErrors).length) {
        setErrors(validationErrors);
        return;
      }
      const res = id
        ? await Api.changelog.updateChangelog(formData, id)
        : await Api.changelog.create(formData);
      if (!res?.status === RESPONSE_STATUS_CODES.incorrectData) {
        throw new Error(res?.data?.message);
      }
      setSuccessMsg(getSuccessMessage(res));
      history.push('/what-is-new/list');
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  useEffect(() => {
    if (data?.data) setFormData(data.data);
  }, [data]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <FormControl
            variant="outlined"
            className={`w-100 my-2 ${errors.appId ? classes.selectError : ''}`}
            error={!!errors.appId}>
            <InputLabel id="type-select-label">App *</InputLabel>
            <Select
              labelId="app-label"
              id="app-id"
              fullWidth
              label="App *"
              name="appId"
              value={formData.appId}
              required
              onChange={handleSelect}>
              {APP_IDS.map((appId, idx) => (
                <MenuItem key={idx} value={appId.value}>
                  {appId.name}
                </MenuItem>
              ))}
            </Select>
            {errors.appId && (
              <FormHelperText className={classes.errHelperText}>
                {errors.appId}
              </FormHelperText>
            )}
          </FormControl>
          <TextField
            {...input(FORM_FIELD_NAMES_OBJ.version)}
            onChange={handleChange}
            value={formData.version}
            label={FORM_LABELS_OBJ.version}
            helperText={errors.version}
            error={!!errors.version}
          />
          <FormControl
            variant="outlined"
            className={`w-100 my-2 ${errors.slug ? classes.selectError : ''}`}
            error={!!errors.slug}>
            <InputLabel id="page-select-label">Page *</InputLabel>
            <Select
              labelId="page-select-label"
              id="page-id"
              fullWidth
              label="Page *"
              name="slug"
              value={formData.slug}
              required
              onChange={handleSelect}>
              {APP_PAGES.map((page, idx) => (
                <MenuItem key={idx} value={page}>
                  {page}
                </MenuItem>
              ))}
            </Select>
            {errors.slug && (
              <FormHelperText className={classes.errHelperText}>
                {errors.slug}
              </FormHelperText>
            )}
          </FormControl>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};
