import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  makeStyles
} from '@material-ui/core';
import * as Api from 'service';
import { changelogItemValidationRules } from '../validators';
import { getError, getSuccessMessage } from 'appHelpers';
import useStore from 'context';
import {
  ADD_NEW_ITEM,
  CHANGELOG_ITEM_FIELD_KEYS,
  CHANGELOG_ITEM_FIELD_LABELS,
  CHANGE_PICTURE,
  UPLOAD_PICTURE
} from '../constants';
import { BTN_TEXTS } from 'constants.js';

const imageBaseUrl = process.env.REACT_APP_imagesBaseUrl;

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  imageUploadContainer: {
    position: 'relative',
    cursor: 'pointer',
    width: 152,
    border: '1px solid gray',
    borderRadius: 6,
    '&:hover $imageOverlay': {
      display: 'flex'
    }
  },
  errorBorder: {
    borderColor: '#f83245'
  },
  imagePreview: {
    width: 150,
    maxHeight: 200,
    objectFit: 'contain',
    borderRadius: '6px'
  },
  imageUploadEmptyBlock: {
    width: 150,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    cursor: 'pointer'
  },
  imageOverlay: {
    display: 'none',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    textAlign: 'center'
  }
}));

const initialItemData = {
  title: '',
  description: '',
  image: null
};

export const CreateChangelogItemDialog = ({
  open,
  handleClose,
  getChangelogData,
  lang,
  order,
  updatingItem
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [itemData, setItemData] = useState(initialItemData);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const { setErrorMsg, setSuccessMsg } = useStore();

  const handleImageChange = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        setItemData({ ...itemData, image: file });
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(file);
      }
      setErrors({ ...errors, image: null });
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    setItemData((prevItemData) => ({ ...prevItemData, [name]: value }));
  };

  const validateField = (name, value) => {
    const rule = changelogItemValidationRules[name];
    if (Array.isArray(rule)) {
      return rule
        .map((r) => (r.test(value) ? '' : r.message))
        .filter(Boolean)[0];
    }
    return rule.test(value) ? '' : rule.message;
  };

  const validateForm = () => {
    const { title, description, image } = itemData;
    const newErrors = {};
    newErrors.title = validateField(CHANGELOG_ITEM_FIELD_KEYS.title, title);
    newErrors.description = validateField(
      CHANGELOG_ITEM_FIELD_KEYS.description,
      description
    );
    newErrors.image = validateField(CHANGELOG_ITEM_FIELD_KEYS.image, image);

    setErrors(newErrors);
    return Object.values(newErrors).every((e) => e === '');
  };

  const handleSubmit = async () => {
    const { title, description, image } = itemData;
    if (!validateForm()) {
      return;
    }
    const formData = new FormData();
    formData.append(CHANGELOG_ITEM_FIELD_KEYS.title, title);
    formData.append(CHANGELOG_ITEM_FIELD_KEYS.description, description);
    formData.append(CHANGELOG_ITEM_FIELD_KEYS.lang, lang);
    formData.append(CHANGELOG_ITEM_FIELD_KEYS.media, image);
    formData.append(
      CHANGELOG_ITEM_FIELD_KEYS.order,
      updatingItem?.order || order
    );
    formData.append(CHANGELOG_ITEM_FIELD_KEYS.changelogId, id);

    try {
      const response = updatingItem
        ? await Api.changelog.updateChangelogItem(formData, updatingItem.id)
        : await Api.changelog.createChangelogItem(formData);
      if (response.success) {
        setSuccessMsg(getSuccessMessage(response));
        getChangelogData();
      } else {
        setErrorMsg(getError(response));
      }
    } catch (err) {
      setErrorMsg(getError(err));
    }

    handleClose();
  };

  useEffect(() => {
    if (updatingItem) {
      setItemData({ ...updatingItem, image: updatingItem.media });
      setImagePreview(imageBaseUrl + updatingItem.media);
    }
    return () => {
      setItemData(initialItemData);
      setImagePreview(null);
      setErrors({});
    };
  }, [open, updatingItem]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm">
      <DialogTitle id="form-dialog-title">{ADD_NEW_ITEM}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box
          className={`${classes.imageUploadContainer} ${
            errors.image && classes.errorBorder
          }`}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="raised-button-file">
            {imagePreview ? (
              <React.Fragment>
                <img
                  src={imagePreview}
                  alt="Changelog"
                  className={classes.imagePreview}
                />
                <Box className={classes.imageOverlay}>
                  <span>{CHANGE_PICTURE}</span>
                </Box>
              </React.Fragment>
            ) : (
              <Box className={classes.imageUploadEmptyBlock}>
                {UPLOAD_PICTURE}
              </Box>
            )}
          </label>
        </Box>
        {errors.image && <FormHelperText error>{errors.image}</FormHelperText>}
        <TextField
          autoFocus
          margin="dense"
          id={CHANGELOG_ITEM_FIELD_KEYS.title}
          label={CHANGELOG_ITEM_FIELD_LABELS.title}
          name={CHANGELOG_ITEM_FIELD_KEYS.title}
          type="text"
          fullWidth
          value={itemData.title}
          onChange={handleChange}
          error={!!errors.title}
          helperText={errors.title}
        />
        <TextField
          margin="dense"
          id={CHANGELOG_ITEM_FIELD_KEYS.description}
          name={CHANGELOG_ITEM_FIELD_KEYS.description}
          label={CHANGELOG_ITEM_FIELD_LABELS.description}
          type="text"
          fullWidth
          multiline
          rows={4}
          value={itemData.description}
          onChange={handleChange}
          error={!!errors.description}
          helperText={errors.description}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {BTN_TEXTS.cancel}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {BTN_TEXTS.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
