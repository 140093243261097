import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Card,
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  FormHelperText,
  Select
} from '@material-ui/core';

import { useText, useGetData, usePutData, usePostData } from '../../hooks';
import * as Api from '../../service';
import { Save } from '../../components';
import { allowNumberAndSingleDot } from 'regexes';
import { validationRules } from './validators';
import { FORM_FIELD_NAMES_OBJ, FORM_LABELS_OBJ } from './constants';
import { UI_TEXTS_OBJ } from 'pages/surveys/constants';

const useStyles = makeStyles(() => ({
  errHelperText: {
    color: '#f83245'
  },
  selectError: {
    '& .MuiOutlinedInput-root.Mui-error': {
      '& fieldset': {
        borderColor: '#f83245'
      },
      '&:hover fieldset': {
        borderColor: '#f83245'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#f83245'
      }
    }
  }
}));

const initForm = {
  code: '',
  title: '',
  longitude: '',
  latitude: '',
  location: {
    type: 'Point',
    coordinates: []
  },
  type: ''
};

const Form = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [data, , refresh] = useGetData(id && Api.buoys.getById, id);
  const [res] = useGetData(Api.spotTypes.getAll);
  const spotTypes = res?.data || [];
  const [errors, setErrors] = useState({});
  const resetErrors = () => setErrors({});
  const postCallback = usePostData(Api.buoys.create, refresh, resetErrors);
  const updateCallback = usePutData(Api.buoys.update, refresh, resetErrors);
  const [form, setForm] = useState(data || initForm);

  const validate = (text) => {
    const errorMessages = {};

    for (let field in validationRules) {
      const rules = Array.isArray(validationRules[field])
        ? validationRules[field]
        : [validationRules[field]];
      for (let rule of rules) {
        if (
          !rule.test(
            typeof text[field] === 'string' ? text[field].trim() : text[field]
          )
        ) {
          errorMessages[field] = rule.message;
          break;
        }
      }
    }

    return errorMessages;
  };

  const [, , , input] = useText(data || initForm);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: null });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const validationErrors = validate(form);
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }

    id ? updateCallback(form) : postCallback(form);
  };

  useEffect(() => {
    if (data) setForm(data);
  }, [data]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            {...input(FORM_FIELD_NAMES_OBJ.code)}
            value={form[FORM_FIELD_NAMES_OBJ.code]}
            onChange={handleChange}
            label={`${FORM_LABELS_OBJ.code}*`}
            helperText={errors.code}
            error={!!errors.code}
          />
          <TextField
            {...input(FORM_FIELD_NAMES_OBJ.title)}
            value={form[FORM_FIELD_NAMES_OBJ.title]}
            onChange={handleChange}
            label={`${FORM_LABELS_OBJ.title}*`}
            helperText={errors.title}
            error={!!errors.title}
          />
          <TextField
            {...input(FORM_FIELD_NAMES_OBJ.latitude)}
            value={form[FORM_FIELD_NAMES_OBJ.latitude]}
            label={`${FORM_LABELS_OBJ.latitude}*`}
            helperText={errors.latitude}
            error={!!errors.latitude}
            onChange={(e) => {
              // allow only numbers and single dot
              if (allowNumberAndSingleDot.test(e.target.value)) {
                handleChange(e);
              }
            }}
          />

          <TextField
            {...input(FORM_FIELD_NAMES_OBJ.longitude)}
            value={form[FORM_FIELD_NAMES_OBJ.longitude]}
            label={`${FORM_LABELS_OBJ.longitude}*`}
            helperText={errors.longitude}
            error={!!errors.longitude}
            onChange={(e) => {
              // allow only numbers and single dot
              if (allowNumberAndSingleDot.test(e.target.value)) {
                handleChange(e);
              }
            }}
          />
          <FormControl
            error={!!errors.type}
            variant="outlined"
            fullWidth
            className="my-2">
            <InputLabel>{UI_TEXTS_OBJ.spotType}*</InputLabel>
            <Select
              className={`capitalize ${errors.type ? classes.selectError : ''}`}
              value={form.type}
              onChange={(e) => setForm({ ...form, type: e.target.value })}
              label={`${UI_TEXTS_OBJ.targetDevice} *`}>
              {spotTypes.map((type, idx) => (
                <MenuItem className="capitalize" key={idx} value={type._id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.type && (
              <FormHelperText className={classes.errHelperText}>
                {errors.type}
              </FormHelperText>
            )}
          </FormControl>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
