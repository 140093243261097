import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography, ButtonGroup, Card, Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import './style.scss';

import * as Api from 'service';
import {
  DEVICES_OBJ,
  SELECTED_DEVICE_LABEL,
  IMPORT_DOCUMENT_TEXT,
  DRAGN_DROP_FILE
} from './constants';
import { BTN_TEXTS, BUTTON_VARIANTS } from 'constants.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function LivePreviewExample() {
  const [activeTab, setActiveTab] = useState(DEVICES_OBJ.android.name);

  const selectedDevice =
    activeTab === DEVICES_OBJ.ios.value
      ? DEVICES_OBJ.ios.name
      : DEVICES_OBJ.android.name;
  const platform = selectedDevice?.toLowerCase();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedFile(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false
  });

  const handleSave = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (uploadedFile) {
      const formData = new FormData();
      formData.append('file', uploadedFile);
      formData.append('platform', platform);
      await Api.main.importFile(formData, platform);
    }
    window.location.reload();
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xl={12} className="offer-content">
          <Card className="card-box">
            <div className="card-header">
              <div className="card-header--title">
                <b>
                  {SELECTED_DEVICE_LABEL} {selectedDevice}
                </b>
                <small className="d-block text-capitalize mt-1">
                  {IMPORT_DOCUMENT_TEXT}
                </small>
              </div>
              <div className="card-header--actions">
                <ButtonGroup size="small" variant="text">
                  <Button
                    className={clsx('btn-dark btn-transition-none', {
                      active: activeTab === DEVICES_OBJ.ios.value
                    })}
                    onClick={() => {
                      toggle(DEVICES_OBJ.ios.value);
                    }}>
                    {DEVICES_OBJ.ios.name}
                  </Button>
                  <Button
                    className={clsx('btn-dark btn-transition-none', {
                      active: activeTab === DEVICES_OBJ.android.value
                    })}
                    onClick={() => {
                      toggle(DEVICES_OBJ.android.value);
                    }}>
                    {DEVICES_OBJ.android.name}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <div className="content">
              <div className={'tab-item-wrapper active'}>
                <>
                  <div className="content dragndrop">
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>{DRAGN_DROP_FILE}</p>
                    </div>
                    {uploadedFile && <p>Selected File: {uploadedFile.name}</p>}
                  </div>
                </>
              </div>
              {uploadedFile && (
                <Button
                  onClick={handleSave}
                  variant={BUTTON_VARIANTS.contained}
                  className="m-2 btn-primary btn-gradient shadow-none bg-night-sky submit_btn">
                  {BTN_TEXTS.submit}
                </Button>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
