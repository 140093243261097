import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { WrapperSeamless } from 'layout-components';
import Table from 'components/tables/table';
import { useGetList } from 'hooks';
import * as Api from 'service';
import useStore from 'context';
import { TABLE_HEAD_TITLES } from 'constants.js';
import {
  BUOYS_QUERY_PARAMS,
  CUSTOM,
  FORM_NAMES,
  SORT_DIRECTIONS,
  STATIONS,
  STATION_TYPE_NAMES
} from './constants';
import { formatDateDayMonthYear } from 'helpers/dateFormatHelpers';
import { QUERY_KEY } from './constants';
import MainSelect from 'components/MainSelect';
import { getError, getSuccessMessage } from 'appHelpers';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    margin: theme.spacing(1)
  }
}));

const defaultFilterState = {
  type: ''
};

export default function HomeClients() {
  const classes = useStyles();
  const location = useLocation();
  const { setRemove, setSuccessMsg, setErrorMsg } = useStore();
  const isCustom = location.pathname.includes(CUSTOM);
  const defaultQuery = {
    sort: BUOYS_QUERY_PARAMS.createdAt,
    order: SORT_DIRECTIONS.desc,
    custom: isCustom
  };
  const [query, setQuery] = useState(defaultQuery);
  const [filterState, setFilterState] = useState(defaultFilterState);

  const [items, total, refresh, setItems] = useGetList(Api.buoys.getAll, query);
  const [spotTypes = []] = useGetList(Api.spotTypes.getAll);

  const handleDelete = async (id) => {
    try {
      setRemove(() => async () => {
        const res = await Api.buoys.delete(id);
        setSuccessMsg(getSuccessMessage(res));
        refresh();
      });
    } catch (err) {
      setErrorMsg(getError(err));
    }
  };

  useEffect(() => {
    setQuery({
      ...query,
      custom: isCustom
    });
  }, [location.pathname]);

  const rows = items?.map((item) => (
    <>
      {!isCustom && (
        <td className="text-left px-4">
          <div className="font-size-sm">{item.code}</div>
        </td>
      )}
      <td className="text-left px-4">
        <div className="font-size-sm">{item.title}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.latitude}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.longitude}</div>
      </td>
      <td className="text-left px-4 capitalize">
        <div className="font-size-sm">{item?.type?.name}</div>
      </td>
      {isCustom && (
        <>
          <td className="text-left px-4">
            <div className="font-size-sm">{item?.user?.name}</div>
          </td>
          <td className="text-left px-4 mw-100 ">
            <div className="font-size-sm auto-overflow-x">
              {item.user?.email}
            </div>
          </td>
          <td className="text-left px-4">
            <div className="font-size-sm">{item.user?.device}</div>
          </td>
          <td className="text-left px-4">
            <div className="font-size-sm">
              {formatDateDayMonthYear(item.createdAt)}
            </div>
          </td>
        </>
      )}
      <td className="d-flex text-right">
        <Link to={`/buoys/create/${item._id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() => handleDelete(item._id || item.id)}
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  const handleChangeFilter = ({ target: { name, value } }) => {
    setQuery((prev) => ({ ...prev, [QUERY_KEY[name]]: `${value}` }));
    setFilterState((prev) => ({ ...prev, [name]: value }));
  };

  const handleResetFilters = () => {
    setFilterState(defaultFilterState);
    setQuery(defaultQuery);
  };

  return (
    <WrapperSeamless className="overflow-x-auto">
      <div className="filter_actions_container">
        <MainSelect
          className={classes.selectContainer}
          label={FORM_NAMES.spotType}
          name={FORM_NAMES.type}
          value={filterState.type}
          onChange={handleChangeFilter}
          items={spotTypes}
          hasReset
          resetHandler={handleResetFilters}
        />
      </div>
      <Table
        noCreate={isCustom}
        tableContainerClassName="overflow-x-auto"
        fields={
          <>
            {!isCustom && (
              <th className="text-left px-4">
                <span className="mr-3">{TABLE_HEAD_TITLES.code}</span>
              </th>
            )}
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.title}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.latitude}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.longitude}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.type}</span>
            </th>
            {isCustom && (
              <>
                <th className="text-left px-4">
                  <span className="mr-3">{TABLE_HEAD_TITLES.username}</span>
                </th>
                <th className="text-left px-4">
                  <span className="mr-3">{TABLE_HEAD_TITLES.email}</span>
                </th>
                <th className="text-left px-4">
                  <span className="mr-3">{TABLE_HEAD_TITLES.device}</span>
                </th>
                <th className="text-left px-4">
                  <span className="mr-3">{TABLE_HEAD_TITLES.createdAt}</span>
                </th>
              </>
            )}
          </>
        }
        noDrag
        title={`${
          isCustom ? STATION_TYPE_NAMES.custom : STATION_TYPE_NAMES.admin
        } ${STATIONS}`}
        items={items}
        setItems={setItems}
        total={total}
        titles
        rows={rows}
        createLink="buoys/create"
      />
    </WrapperSeamless>
  );
}
