import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { WrapperSeamless } from 'layout-components';
import Table from 'components/tables/table';
import { useGetList } from 'hooks';
import * as Api from 'service';
import useStore from 'context';
import { TABLE_HEAD_TITLES } from 'constants.js';
import { BUOYS_QUERY_PARAMS, SORT_DIRECTIONS } from './constants';
import { formatDateDayMonthYear } from 'helpers/dateFormatHelpers';

export default function WhatsNewList() {
  const history = useHistory();
  const { setRemove } = useStore();
  const [query, setQuery] = useState({
    sort: BUOYS_QUERY_PARAMS.createdAt,
    order: SORT_DIRECTIONS.desc
  });

  const [items, total, refresh, setItems] = useGetList(
    Api.changelog.getAllChangelogs,
    query
  );

  const rows = items?.map((item, idx) => (
    <tr
      key={idx}
      className="cursor-pointer px-2 py-2 m-2 my-3 font-weight-bold"
      onClick={() => history.push(`/what-is-new/view/${item.id}`)}>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.version}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.slug}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.platform}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {formatDateDayMonthYear(item.createdAt)}
        </div>
      </td>
      <td className="text-right">
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`/what-is-new/edit/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setRemove(() => () => Api.changelog.delete(item.id).then(refresh));
          }}
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="error" />
        </Button>
      </td>
    </tr>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.version}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.page}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.platform}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.createdAt}</span>
            </th>
          </>
        }
        noDrag
        title="Changelogs"
        items={items}
        setItems={setItems}
        total={total}
        titles
        hasOwnRowContainer
        rows={rows}
        createLink="what-is-new/create"
      />
    </WrapperSeamless>
  );
}
