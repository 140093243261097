export const validationRules = {
  title: {
    test: (value) => !!value.trim(),
    message: 'Title is required'
  },
  version: {
    test: (value) => !!value.trim(),
    message: 'Release Version is required'
  },
  device: {
    test: (value) => !!value.trim(),
    message: 'Target Device is required'
  }
};
