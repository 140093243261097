import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

const Save = ({ disabled, onClick = null }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      color="secondary"
      type="submit"
      variant="contained"
      className="d-flex my-3 mx-auto">
      <span className="btn-wrapper--label">Save</span>
      <SaveIcon />
    </Button>
  );
};

export default memo(Save);
