import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';

import { WrapperSeamless } from 'layout-components';
import Table from 'components/tables/table';
import { useGetList } from 'hooks';
import * as Api from 'service';
import useStore from 'context';
import { TABLE_HEAD_TITLES } from 'constants.js';
import { GUEST } from 'constants.js';
import { formatDateDayMonthYear } from 'helpers/dateFormatHelpers';

export default function List() {
  const { setRemove } = useStore();
  const [query, setQuery] = useState({
    sort: 'createdAt',
    order: 'desc'
  });

  const [items, total, refresh, setItems] = useGetList(
    Api.stationSuggestions.getAll,
    query
  );

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.user ? item.user.name : GUEST}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {item.user ? item.user.email : GUEST}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.station_name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.station_id}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {formatDateDayMonthYear(item.createdAt)}
        </div>
      </td>

      <td className="text-right">
        <Button
          onClick={() =>
            setRemove(() => () =>
              Api.stationSuggestions.delete(item._id).then(refresh)
            )
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.email}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.stationName}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.stationId}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{TABLE_HEAD_TITLES.createdAt}</span>
            </th>
          </>
        }
        noDrag
        title="Items"
        items={items}
        setItems={setItems}
        total={total}
        titles
        rows={rows}
        noCreate
      />
    </WrapperSeamless>
  );
}
