import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { CARD_NAMINGS } from 'pages/offers/constants';

const StatisticsCard = ({ title, total, used, trial, sRate }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Divider style={{ margin: '10px 0' }} />
        <Typography variant="body2" color="textSecondary" component="p">
          {CARD_NAMINGS.total} : {total}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {CARD_NAMINGS.used} : {used}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {CARD_NAMINGS.freeTrial} : {trial}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {CARD_NAMINGS.successRate} : {sRate}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
